const socialShare = {

	$window: $(window),

	elem: {
		$socialShare: null
	},

	init: function () {
		this.elem.$socialShare = ($('.social-share').length > 0) ? $('.social-share') : null;

		if (this.elem.$socialShare !== null) {
			this.bindUIActions();
		}
	},

	bindUIActions: function () {
		this.elem.$socialShare.on('click', '.social-media-button', this.share.bind(this));
	},

	share: function (e) {
		e.preventDefault();

		let data = $(e.target).data('share').toLowerCase();
		let title = this.getMetaTitle();
		let url;

		switch (data) {
			case 'email':
				window.location = 'mailto:?subject=' + title + '&body=I thought you might find this article interesting: ' + window.location;
				break;
			case 'facebook':
				url = e.currentTarget + '?u=' + encodeURIComponent(window.location.href) + '&title=' + encodeURIComponent(title);
				this.openWindow(600, 700, url);
				break;
			case 'linkedin':
				url = e.currentTarget + '?mini=true' + '&url=' + encodeURIComponent(window.location.href);
				this.openWindow(600, 700, url);
				break;
			case 'twitter':
				url = e.currentTarget + '?url=' + encodeURIComponent(window.location.href) + '&text=' + encodeURIComponent(title);
				this.openWindow(450, 275, url);
				break;
			default:
				break;
		}
	},

	getMetaTitle: function () {

		let pageTitle = document.title;
		let hOne = $('h1').text().trim();
		let $openGraphTitleTag = $('meta[property="og:title"]');
		let ogTitle = ($openGraphTitleTag.length > 0) ? $('meta[property="og:title"]').val().trim() : '';

		let shareTitle = '';

		if (pageTitle !== '') {
			shareTitle = pageTitle;
		}

		if (hOne !== '') {
			shareTitle = hOne;
		}

		if (ogTitle !== '') {
			shareTitle = ogTitle;
		}

		return shareTitle;

	},

	openWindow: function (width, height, url) {
		// http://stackoverflow.com/questions/12882689/launch-facebook-share-popup-at-center-of-screen
		let leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
		let topPosition = (window.screen.height / 2) - ((height / 2) + 50);
		let windowFeatures = 'status=no,height=' + height + ',width=' + width + ',resizable=yes,left=' + leftPosition + ',top=' + topPosition + ',screenX=' + leftPosition + ',screenY=' + topPosition + ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no';

		window.open(url, 'socialshare', windowFeatures);
	}

};

export {socialShare};
