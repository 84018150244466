import { toggleVideoPlay } from '../lib/utils';

/**
 * Masthead component.
 * @module components/standardLevelMasthead
*/
const standardLevelMasthead = {

	elem: {
		$standardLevelMasthead: null
	},

	init() {
		this.elem.$standardLevelMasthead = ($('.standard-level-masthead').length > 0) ? $('.standard-level-masthead') : null;

		if (this.elem.$standardLevelMasthead === null) {
			return;
		}

		if (!this.elem.$standardLevelMasthead.hasClass('standard-level-masthead--has-video')) {
			return;
		}

		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$standardLevelMasthead.on('click', '.pause-button', toggleVideoPlay);
	}

};

export { standardLevelMasthead };
