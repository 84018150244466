import { toggleVideoPlay } from '../lib/utils';

/**
 * Masthead component.
 * @module components/masthead
*/
const masthead = {

	elem: {
		$masthead: null
	},

	init() {
		this.elem.$masthead = ($('.masthead').length > 0) ? $('.masthead') : null;

		if (this.elem.$masthead === null) {
			return;
		}

		if (!this.elem.$masthead.hasClass('masthead--has-video')) {
			return;
		}

		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$masthead.on('click', '.pause-button', toggleVideoPlay);
	}

};

export { masthead };