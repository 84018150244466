import { debounce, throttle } from '../lib/lodash.custom';

const Table = class Table {
	constructor(element) {
		this.element = element;
		this.init();
	}

	init() {
		this.bindUIActions();
		this.onResize(); // On page load...
	}

	bindUIActions() {
		$(window).on('resize', debounce(this.onResize.bind(this), 100));

		$(this.element).find('.table-container').on('scroll', throttle(this.onScroll.bind(this), 100));
	}

	onResize() {
		let tableContainerWidth = $(this.element).outerWidth();
		let tableWidth = $(this.element).find('table').outerWidth();

		let $tableWrapper = $(this.element);

		$tableWrapper.toggleClass('table-wrapper--right-shadow', (tableWidth > tableContainerWidth) ? true : false);
	}

	onScroll(e) {
		let $target = $(e.target);
		let $wrapper = $target.parent('.table-wrapper');

		let width = $target.outerWidth();
		let scrollLeft = $target.scrollLeft();
		let scrollWidth = $target[0].scrollWidth;

		$wrapper
			.toggleClass('table-wrapper--left-shadow', (scrollLeft === 0) ? false : true)
			.toggleClass('table-wrapper--right-shadow', ((scrollWidth - width) === scrollLeft) ? false : true);
	}
}

export { Table };