import { dirButton, mql } from '../lib/utils';

/**
 * Media component.
 * @module components/media
*/
const media = {

	elem: {
		$slider: $('.slider-wrapper--type-image-caption .slider')
	},

	options: {
		adaptiveHeight: true,
		arrows: true,
		dots: false,
		draggable: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 425,
		rows: 0,
		nextArrow: dirButton('', 'Next slide'),
		prevArrow: dirButton('dir-button--is-prev', 'Previous slide'),
		responsive: [
			{
				breakpoint: 767,
				settings: 'unslick'
			}
		]
	},

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$slider.slick(this.options);
		this.elem.$slider.on('mouseleave', '.dir-button', (e) => $(e.target).blur());

		mql.medium.addListener((mq) => {
			if (mq.matches) {
				if (!this.elem.$slider.hasClass('slick-initialized')) {
					this.elem.$slider.slick(this.options);
				}
			}
		});
	}

};

export { media } ;