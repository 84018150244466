/**
 * Results component.
 * @module components/results
*/
const results = {

	elem: {
		$results: $('.results')
	},

	init() {
		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$results.on('mouseover mouseout focus blur', '.profile-card__link, .program-card__link', this.toggleState.bind(this));
	},

	toggleState(e) {
		const $target = $(e.target);
		const $card = $target.closest('.profile-card, .program-card');

		if ($card.hasClass('profile-card')) {
			$card.toggleClass('profile-card--is-active', ($card.hasClass('profile-card--is-active')) ? false : true);
		} else if ($card.hasClass('program-card')) {
			$card.toggleClass('program-card--is-active', ($card.hasClass('program-card--is-active')) ? false : true);
		}
	}

};

export { results };
