import { ready } from './lib/utils';
import { plugin } from './lib/plugin';
import { Accordion } from './modules/accordion.js';
import { masthead } from './modules/masthead.js';
import { media } from './modules/media.js';
import { results } from './modules/results';
import { standardLevelMasthead } from './modules/standardLevelMasthead.js';
import { socialShare } from './modules/socialShare';
import { Table } from './modules/table.js';

const level = {

	init() {
		plugin('Accordion', Accordion);
		plugin('Table', Table);

		$('.accordion').Accordion();
		$('.table-wrapper').Table();

		masthead.init();
		media.init();
		results.init();
		standardLevelMasthead.init();
		socialShare.init();
	}

};

ready(function () {
	level.init();
});
